"use client"

import { usePathname, useRouter } from "next/navigation"
import { NextUIProvider } from "@nextui-org/react"

export function NextUITheme({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const currentPath = usePathname()

  return (
    <NextUIProvider
      navigate={(path: string) => {
        if (path !== currentPath) router.push(path)
      }}
    >
      {children}
    </NextUIProvider>
  )
}
